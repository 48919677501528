.btn {
  position: relative;
  padding: 1em 1.5em;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  line-height: 1;
  transition: background-color .3s ease-out;
  overflow: hidden;

  &-primary {
    background-color: $global-blue-light;

    &:hover {
      background-color: darken($global-blue-light, 10%);
    }

    &.btn-arrow {
      &:after {
        background-color: darken($global-blue-light, 10%);
      }

      &:hover {
        &:after {
          background-color: darken($global-blue-light, 20%);
        }
      }
    }
  }

  &-secondary {
    background-color: $global-blue;

    &:hover {
      background-color: darken($global-blue, 10%);
    }
  }

  &-transparent {
    background-color: transparent;
    color: white;

    &:hover {
      background-color: rgba(0,0,0,.1);
      color: white;
    }
  }

  &-transparent-dark {
    background-color: transparent;

    &:hover {
      background-color: rgba(0,0,0,.1);
    }
  }

  &-gold {
    background-color: $global-gold;

    &:hover {
      color: white;
      background-color: darken($global-gold, 10%);
    }
  }

  &-gold-dark {
    background-color: darken($global-gold, 10%);

    &:hover {
      color: white;
      background-color: darken($global-gold, 20%);

      &:after {
        background-color: darken($global-gold, 30%);
      }
    }

    &:after {
      background-color: darken($global-gold, 20%);
    }
  }

  &-arrow {
    padding-right: 4.5em;

    &:after {
      position: absolute;
      padding: 1em 1.125em;
      content: '\f00a';
      top: 0;
      right: 0;
      font-family: 'Globalhealth';
      transition: background-color .3s ease-out;
    }
  }

  &-input {
    height: 54px;
  }

  &-search {
    padding: 1em 3em;
    padding-right: 6.375em;
    background-color: $global-blue-dark;

    &:hover {
      background-color: darken($global-blue-dark, 10%);
    }

    &-icon {
      position: absolute;
      padding: .75em;
      width: 54px;
      top: 0;
      right: 0;
      transition: background-color .3s ease-out;
      background-color: darken($global-blue-dark, 10%);
    }

    &:hover {
      &-icon {
        background-color: darken($global-blue-dark, 20%);
      }
    }
  }

  &-more {
    padding-right: 4.5em;
    background-color: $global-blue-light;

    &:hover {
      background-color: darken($global-blue-light, 10%);
    }

    &:after {
      position: absolute;
      padding: 1em 1.125em;
      content: '\f012';
      top: 0;
      right: 0;
      font-family: 'Globalhealth';
      transition: background-color .3s ease-out;
      background-color: darken($global-blue-light, 10%);
    }

    &:hover {
      &:after {
        background-color: darken($global-blue-light, 20%);
      }
    }
  }
}

.btn-group-center {
  padding: 1em;
  width: 100%;
  background-color: $global-blue;
  text-align: center;

  .btn {
    margin: 0 .5em;

    @media (max-width: $screen-md) {
      display: block;
      margin: .5em 0;
    }
  }
}
