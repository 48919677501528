.table {

  thead {
    background-color: $global-blue-5;
    text-transform: uppercase;
    color: $global-blue-1;

    th {
      padding: 1.25em 1em !important;
      line-height: 1 !important;
      border-bottom: 0 !important;
    }
  }

  td {
    padding: 1em !important;
    border-top: 0 !important;
    border-bottom: 1px solid $gray-lighter;
  }
}
