.landing-medicare-network {
  padding: 4rem;
  background-color: $global-primary;
  text-align: center;
  color: white;

  &-title {
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 1.5em;
    font-weight: 400;
    color: white;
  }

  &-subtitle {
    margin-top: 1.5em;
    color: $global-primary-light;
    font-weight: 700;
    font-size: 1.5em;
  }

  &-icon {
    width: 66%;
  }

  &-item {
    margin: .25em 0;
    font-size: 1.1em;
    font-weight: 200;
  }
}
