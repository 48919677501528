.landing-osr-alert {
  background-color: $global-red;
  color: white;
  text-align: center;

  &-message {
    position: relative;
    margin-bottom: 0;
    line-height: 2;
  }

  &-icon {
    width: 1.25em;
    margin-top: -.25em;
    margin-right: .25em;
  }
}