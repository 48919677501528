.panel-magnet {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  flex: 1;

  .panel-title {
    margin-bottom: .5em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
  }

  .panel-description {
    margin-bottom: 0;
    font-size: 18px;
  }

  a {
    color: white;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  &-blue {
    background-color: $global-blue-dark;
  }

  &-gold {
    background-color: $global-gold;
  }

  &-purple {
    background-color: $global-purple;
  }
}

.panel-magnet-group {
  display: flex;
  margin-bottom: 0;
  height: 187px;

  @media (min-width: $screen-md) {
    &:before {
      position: absolute;
      content: "";
      left: 0;
      width: 50vw;
      height: 187px;
      background-color: $global-purple;
      z-index: 49;
    }

    &:after {
      position: absolute;
      content: "";
      right: 0;
      width: 50vw;
      z-index: 49;
      height: 187px;
      background-color: $global-blue-dark;
    }
  }

  @media (max-width: $screen-md) {
    flex-direction: column;
    height: auto;
  }
}

.panel-magnet-collapse-container {
  position: relative;
  flex: 1;
}

.panel-magnet-collapse {
  display: flex;
  padding: 3em;
  min-height: 187px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;

  @media (min-width: $screen-md) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  @media (max-width: $screen-md) {
    small {
      font-size: 80%;
    }
  }

  .panel-title {
    margin: .35em 0;
    line-height: 1.25;
  }

  a[data-toggle="collapse"] {
    padding: 0;

    &:after {
      content: "\f00d";
      position: absolute;
      top: .5em;
      right: 1em;
      font-family: 'Globalhealth';
      font-size: 1.25em;
    }

    &.collapsed {
      &:after {
        content: "\f00e";
      }
    }
  }
}
