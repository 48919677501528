.pagination-container {
  margin: 2em 0;
  padding: 1em 1em .8em;
  background-color: $global-primary-light;
  line-height: 1;

  .pagination {
    margin: 0;

    a {
      padding: 0 1em;
    }

    li {
      a {
        background-color: transparent;
        border: 0;
        line-height: 1;
        border-right: 1px solid $gray-light;
      }

      &:last-child a {
        border-right: 0;
      }

      &.active {
        a {
          color: $gray-light;
          font-weight: 300;
          border-radius: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    &-link {
      float: right;
      text-transform: uppercase;
      @extend .arrow-link;
    }
  }
}
