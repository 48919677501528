.landing-medicare-service {
  &-header {
    margin: 1em 0;
    font-weight: 900;
  }

  &-item {
    margin: 0;
    font-weight: 100;
  }

  &-item-container {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .landing-medicare-service-map {
    width: 75%;
  }
}
