.landing-osr-help {
  text-align: center;
  padding: 4rem 4rem 2rem 4rem;
  background-color: $global-primary-light;

  &-subtitle {
    font-size: 1.25em;
    font-weight: 300;
  }

  &-title {
    font-size: 2em;
    font-weight: 400;
  }

  .landing-osr-help-icon {
    margin: 2rem auto;
    width: 100%;

    @media (max-width: $screen-sm) {
      width: 50%;
    }
  }
}
