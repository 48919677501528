.landing-zero-rev {
  .sprite {
    &.sprite-landing-android {
      background-position: -5px -5px;
      width: 230px;
      height: 82px;
    }
    &.sprite-landing-apple {
      background-position: -5px -97px;
      width: 228px;
      height: 80px;
    }
    &.sprite-landing-icon-agency {
      background-position: -5px -187px;
      width: 58px;
      height: 84px;
    }
    &.sprite-landing-icon-education {
      background-position: -5px -281px;
      width: 119px;
      height: 88px;
    }
    &.sprite-landing-icon-facebook {
      background-position: -5px -379px;
      width: 8px;
      height: 14px;
    }
    &.sprite-landing-icon-mail {
      background-position: -5px -403px;
      width: 50px;
      height: 51px;
    }
    &.sprite-landing-icon-tech_center {
      background-position: -5px -464px;
      width: 99px;
      height: 67px;
    }
    &.sprite-landing-icon-twitter {
      background-position: -5px -541px;
      width: 16px;
      height: 11px;
    }
    &.sprite-landing-logo {
      background-position: -5px -562px;
      width: 202px;
      height: 85px;
    }
    &.sprite-landing-ncqa {
      background-position: -5px -657px;
      width: 71px;
      height: 53px;
    }
    &.sprite-landing-person-off {
      background-position: -5px -720px;
      width: 37px;
      height: 92px;
    }
    &.sprite-landing-person-on {
      background-position: -5px -822px;
      width: 37px;
      height: 92px;
    }
    &.sprite-landing-phone {
      background-position: -5px -924px;
      width: 146px;
      height: 146px;
    }
    &.sprite-landing-zerorev {
      background-position: -5px -1080px;
      width: 374px;
      height: 430px;
    }
  }

  .sprite-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAABMCAYAAAAm/ER6AAACzElEQVR4Xu3bsWocMRAG4H9IlWdIkSat8wJpgiHgIhAw5CHySunTBgIpDMbGjV8gbt2k8DMY0kyYsIJls1qNRjO6vb3d9m5X+vTrtNo9ibDRgzbqwg47tmT3xJj5AsAXAJ+J6E/PBJn5JYBvAL4S0ZWmbFViA+o7AClALvypF25ASdnSsM9D2dclXBHGzB8A/BhQ6XqCuyQiKSjsGFBSttQhHSrcIiyD6oLLoMa4j0R0m2vVLIyZzwH8nCQ1vU5IcgWUCjcLU6JCklOiirgc7BeAs4ofj0tylahUvQciejutaw72CsAdgDe9cEbUI4D3RPSkgsmXmLkbzhsl9S+Nihac3GPkPqe6FUSgirCG5FS4KJQKFoWLRKlh3rhoVBXMC9cDVQ1rxQ1DssxmZFajPbJD+tIFipPguZONt4IbAC/kvqMVATChTImlShlxFSY7qgnW0C01OHNS6eKmrjiuWUByzajmxAK6pQvKDebULd1QrrBGnCvKHTbC3QN4rRklAPwG8G7u0UN5/uzXmgePhfucBheCCklsMqAs4cJQobBCtwxFhcMyuHBUF9gIJ29z5ZAXrf+9o2gZKObODRk8vCtpud4Os7TaIc/ZEztk61vK3hOztNohz9kTO2TrW8reE7O02mSmv60p1fCyZ/z4cvyT4BlUCjEcF/YbW0B1wYXAFKhwnDusAhWKc4U1vBVe7+u3BlRKzhXnkpgDyh3XDHNEueKaYAEoN5wZFohywZlgRtS6/6o1ov4taBmiWN+f6y2otARpdcshPFCjxxhZWzxdElt6Mqq6z6l+Y56oXrgiLALVA3d6y/qMSZmW0EYMKKe1dJaZN7vYWbPmPo0Bpu6XG9sru6Usz53dWHBaGwpGw/HcvpaQpKYJhm0BKeBcu19lt2zftDPCyRanbW2zmuC2tTGuNDtd4+fFueIaK62p0w7TtNKavrPZxP4CEqT2XAQueXsAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    display: block;
    &.sprite-icon_white_accordion_collapsed {
      background-position: -5px -5px;
      width: 44px;
      height: 28px;
    }
    &.sprite-icon_white_accordion_expanded {
      background-position: -5px -43px;
      width: 44px;
      height: 28px;
    }
  }

  a {
    text-decoration: none;
  }

  .line-orange {
    background-image: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 44%, #c16c18 45%, #c16c18 55%, rgba(0, 0, 0, 0) 56%, rgba(0, 0, 0, 0) 100%);
  }

  strong {
    font-weight: 900;
  }

  .sprite.inline {
    display: inline-block;
  }

  .slice-header {
    h1 {
      margin: 0;
      color: #ddedf9;
      background-color: #c16c18;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
      padding: .5em;
      a {
        color: #ddedf9;
      }
    }
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
    }
    .logo-header {
      position: relative;
      background-color: #022a3a;
      height: 100px;
      z-index: 2;
    }
    .logo-container {
      margin: 0 auto;
      padding: 3em;
      padding-top: .2em;
      padding-bottom: 1.3em;
      margin-bottom: -1.3em;
      background-position: top center;
      background-repeat: no-repeat;
      width: 300px;
      .sprite {
        margin: 0 auto;
      }
    }
  }
  .main {
    position: relative;
    padding: 2em;
    background-repeat: no-repeat;
    background-position: 50% -150px;

    @media (max-width: $screen-sm) {
      padding: 2em .5em;
    }

    h2 {
      text-align: center;
      font-size: 26px;
      padding: .2em;
    }
    h3 {
      text-align: center;
      font-weight: 900;
      color: #c16c18;
      font-size: 46px;
      text-shadow: 6px 6px 4px #f8e9db;
    }
    p {
      text-align: center;
      width: 70%;
      margin: 0 auto;
      margin-top: 1em;
      font-size: 20px;
      padding: 1em;
      line-height: 1.2em;

      @media (max-width: $screen-md) {
        width: 85%;
      }

      @media (max-width: $screen-sm) {
        width: 100%;
      }
    }
  }

  .slice-revolutionary {
    background-image: url("/assets/img/landing/landing-back-2.png");
    color: #ddedf9;
    background-color: #022a3a;
    color: white;
    text-align: center;
    padding-bottom: 2em;
    border-top: 8px solid #c16c18;
    border-bottom: 8px solid #7fa9ae;
    a {
      color: #ddedf9;
    }
    h1 {
      font-size: 62px;
    }
    h2 {
      display: inline-block;
      font-size: 62px;
      background-color: #c16c18;
      margin: .25em;
      margin-top: 0;
      padding: .25em;
    }
    p {
      display: block;
      width: 800px;
      margin: .8em auto;
      padding: 1em;
      word-spacing: .2em;
      line-height: 1.5em;
    }
  }

  .slice-change {
    position: relative;
    margin-top: -20px;
    color: white;
    text-align: center;
    background-image: url("/assets/img/landing/landing-back-1.png");
    background-color: #022a3a;
    h1 {
      background-color: #c16c18;
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 900;
      padding: .2em 0;
    }
    h2 {
      font-weight: 900;
      font-size: 70px;
      margin: .3em 0;
    }
    p {
      width: 800px;
      font-size: 18px;
      line-height: 1.2em;
      padding: 1em 1em;
      padding-top: 0;
      margin: 0 auto;
    }
    .triangle {
      position: absolute;
      left: 470px;
      z-index: 1;
      top: 70px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 25px 35px 0 35px;
      border-color: #c16c18 transparent transparent transparent;
    }
  }
  .slice-highlights {
    h1 {
      font-size: 50px;
      color: #022a3a;
      font-weight: 900;
      text-align: center;
      margin: 0 auto;
      background-image: linear-gradient(to right, #c16c18 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 12px 3px;
      background-repeat: repeat-x;
      padding: .5em 0;
    }
    ul {
      padding: .5em 0;
      list-style-position: outside;
      margin-left: 20%;
      li {
        margin: 0;
        font-size: 20px;
        padding: .25em 0;
      }
      ul {
        padding: 0;
        list-style-type: disc;
        list-style-image: none;
        margin-left: 2em;
      }
    }
  }
  .slice-who {
    h1 {
      margin: 0;
      padding: .5em 0;
      color: #7fa9ae;
      text-align: center;
      font-size: 42px;
      font-weight: 400;
      text-transform: uppercase;
      background-color: white;
    }
    .who-options {
      display: flex;
      background-color: #022a3a;
      color: white;
      justify-content: space-around;
      width: 100%;

      @media (max-width: $screen-xs) {
        flex-direction: column;
      }
    }
    .who {
      display: flex;
      position: relative;
      padding: 1.5em;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      font-size: 28px;

      @media (max-width: $screen-md) {
        padding: 2em 0;
        font-size: 22px;
      }

      .who-sprite-wrap {
        height: 100px;

        .sprite {
          margin: 0 auto;
        }
      }
      p {
        font-size: inherit;
        font-weight: 400;
        margin: 1em auto 2em;
      }
      a {
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 20px;
        &.btn {
          display: block;
          text-transform: uppercase;
          color: #ddedf9;
          background-color: #c16c18;
          font-size: 20px;
          border: 0px solid black;
          padding: .75em 1em;
          border-radius: 6px;

          @media (max-width: $screen-md) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .slice-video {
    color: #425563;
    background-color: #ebf6f5;
    color: #022a3a;
    border-top: 8px solid #c16c18;
    padding: 1em;
    text-align: center;
    a {
      color: #425563;
    }
    h1 {
      font-size: 30px;
      font-weight: 900;
      margin: .2em;
    }
    p {
      font-size: 18px;
      letter-spacing: .09em;
    }
  }
  .slice-materials {
    border-top: 8px solid #7fa9ae;
    padding: 0;
    text-align: center;
    h1 {
      font-size: 42px;
      font-weight: 900;
      margin: .2em;
      text-transform: uppercase;
    }
    a {
      vertical-align: middle;
      &::before {
        display: inline-block;
        content: "";
        background: url("/assets/img/landing/download-icon.png") 0 no-repeat;
        height: 40px;
        width: 36px;
      }
      &::after {
        content: '  [PDF]';
      }
      &:first-of-type {
        text-transform: uppercase;
        display: block;
        color: #022a3a;
        font-size: 18px;
        border-bottom: 8px solid #022a3a;
        font-weight: 900;
        padding: .5em;
        padding-bottom: 2em;
        &:hover {
          color: #425563;
          font-weight: 900;
        }
      }
      &:nth-of-type(2) {
        font-size: 30px;
        margin-top: 1em;
        padding: 1.5em 0;
        display: block;
        color: #ddedf9;
        background-color: #7fa9ae;
        a {
          color: #ddedf9;
        }
        &:hover {
          color: #425563;
        }
      }
    }
  }
  .gal {
    padding-bottom: 260px;
    border-top: 1px dotted black;
    font-size: 12px;
    padding-top: 24px;
    line-height: 1.2em;
    h1 {
      font-size: 14px;
      text-decoration: underline;
      text-transform: uppercase;
    }
    h2 {
      font-size: 14px;
      margin-top: 1em;
      font-weight: 900;
    }
    ul {
   list-style: disc inside;
      ul {
        list-style: circle inside;
        margin-left: 3em;
      }
    }
  }
}
