@import './variables';

// Vendor
@import './vendor/normalize';
@import './vendor/bootstrap';
@import './vendor/pushy';
@import './vendor/slick';

// App
@import './main/action-widget';
@import './main/breadcrumbs';
@import './main/button';
@import './main/container';
@import './main/feature';
@import './main/footer';
@import './main/form';
@import './main/header';
@import './main/jumbotron';
@import './main/list';
@import './main/magnet';
@import './main/news-item';
@import './main/off-canvas';
@import './main/page';
@import './main/pagination';
@import './main/panel';
@import './main/search';
@import './main/sidenav';
@import './main/slick';
@import './main/table';
@import './main/typography';

@import './landing';

hr {
  border-color: rgba(0,0,0,.1);

  @media (max-width: $screen-lg) {
    margin-top: 10px;
    margin-top: 10px;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 53.75%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

html, body {
  @media (max-width: $screen-lg) {
    overflow-x: hidden;
  }
}
