.slick-slider {
  background-color: $global-blue-lighter;
}

.slick-arrow {
  position: absolute;
  background-color: transparent;
  color: $global-blue-0;
  opacity: .8;
  border: 0;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;

  @media (min-width: $screen-sm) {
    top: calc(50% - 1em);
  }

  @media (max-width: $screen-sm) {
    top: .5em;
  }
}

.slick-next {
  left: 2.5%;
}

.slick-prev {
  right: 2.5%;
}

.slick-dots {
  position: absolute;
  list-style-type: none;

  @media (min-width: $screen-sm) {
    left: 0;
    bottom: 0;
  }

  @media (min-width: $screen-lg) {
    bottom: 0;
  }

  @media (max-width: $screen-sm) {
    display: flex;
    padding-left: 0;
    top: .25em;
    width: 100%;
    justify-content: center;
    font-size: 1.25em;
  }

  li {
    opacity: .4;
    transition: opacity .3s ease-out;

    @media (min-width: $screen-sm) {
      float: left;
    }

    @media (max-width: $screen-sm) {
      margin: .75em .25em;
    }

    &.slick-active {
      opacity: .8;
    }

    button {
      padding: 3px 6px 2px 6px;
      background-color: transparent;
      color: $global-blue-0;
      border: 0;
      overflow: hidden;
      height: 2em;
      cursor: pointer;

      &:not(.slick-play-pause):before {
        display: block;
        width: 100%;
        content: '\f013';
        font-family: 'Globalhealth';
        transition: background-color .3s ease-out;
      }

      &.slick-play-pause {
        padding-top: 0px;
      }
    }
  }
}
