.news-item {
  margin: 1.5em 0;
  padding: 1.5em 0;
  border-bottom: 1px solid $gray-lighter;

  &-title {
    margin-top: 0;

    &-link {
      color: $gray-dark;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-date {
    font-style: italic;
    color: $gray-light;
  }

  &:last-child {
    border-bottom: 0;
  }
}
