.landing-zero-fed {

  .sprite {
    &.sprite-landing-android {
      background-position: -5px -5px;
      width: 230px;
      height: 82px;
    }
    &.sprite-landing-apple {
      background-position: -5px -97px;
      width: 228px;
      height: 80px;
    }
    &.sprite-landing-icon-agency {
      background-position: -5px -187px;
      width: 58px;
      height: 84px;
    }
    &.sprite-landing-icon-education {
      background-position: -5px -2i81px;
      width: 119px;
      height: 88px;
    }
    &.sprite-landing-icon-facebook {
      background-position: -5px -379px;
      width: 8px;
      height: 14px;
    }
    &.sprite-landing-icon-mail {
      background-position: -5px -403px;
      width: 50px;
      height: 51px;
    }
    &.sprite-landing-icon-tech_center {
      background-position: -5px -464px;
      width: 99px;
      height: 67px;
    }
    &.sprite-landing-icon-twitter {
      background-position: -5px -541px;
      width: 16px;
      height: 11px;
    }
    &.sprite-landing-logo {
      background-position: -5px -562px;
      width: 202px;
      height: 85px;
    }
    &.sprite-landing-ncqa {
      background-position: -5px -657px;
      width: 71px;
      height: 53px;
    }
    &.sprite-landing-person-off {
      background-position: -5px -720px;
      width: 37px;
      height: 92px;
    }
    &.sprite-landing-person-on {
      background-position: -5px -822px;
      width: 37px;
      height: 92px;
    }
    &.sprite-landing-phone {
      background-position: -5px -924px;
      width: 146px;
      height: 146px;
    }
    &.sprite-landing-zerorev {
      background-position: -5px -1080px;
      width: 374px;
      height: 430px;
    }
  }

  .sprite-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAABMCAYAAAAm/ER6AAACzElEQVR4Xu3bsWocMRAG4H9IlWdIkSat8wJpgiHgIhAw5CHySunTBgIpDMbGjV8gbt2k8DMY0kyYsIJls1qNRjO6vb3d9m5X+vTrtNo9ibDRgzbqwg47tmT3xJj5AsAXAJ+J6E/PBJn5JYBvAL4S0ZWmbFViA+o7AClALvypF25ASdnSsM9D2dclXBHGzB8A/BhQ6XqCuyQiKSjsGFBSttQhHSrcIiyD6oLLoMa4j0R0m2vVLIyZzwH8nCQ1vU5IcgWUCjcLU6JCklOiirgc7BeAs4ofj0tylahUvQciejutaw72CsAdgDe9cEbUI4D3RPSkgsmXmLkbzhsl9S+Nihac3GPkPqe6FUSgirCG5FS4KJQKFoWLRKlh3rhoVBXMC9cDVQ1rxQ1DssxmZFajPbJD+tIFipPguZONt4IbAC/kvqMVATChTImlShlxFSY7qgnW0C01OHNS6eKmrjiuWUByzajmxAK6pQvKDebULd1QrrBGnCvKHTbC3QN4rRklAPwG8G7u0UN5/uzXmgePhfucBheCCklsMqAs4cJQobBCtwxFhcMyuHBUF9gIJ29z5ZAXrf+9o2gZKObODRk8vCtpud4Os7TaIc/ZEztk61vK3hOztNohz9kTO2TrW8reE7O02mSmv60p1fCyZ/z4cvyT4BlUCjEcF/YbW0B1wYXAFKhwnDusAhWKc4U1vBVe7+u3BlRKzhXnkpgDyh3XDHNEueKaYAEoN5wZFohywZlgRtS6/6o1ov4taBmiWN+f6y2otARpdcshPFCjxxhZWzxdElt6Mqq6z6l+Y56oXrgiLALVA3d6y/qMSZmW0EYMKKe1dJaZN7vYWbPmPo0Bpu6XG9sru6Usz53dWHBaGwpGw/HcvpaQpKYJhm0BKeBcu19lt2zftDPCyRanbW2zmuC2tTGuNDtd4+fFueIaK62p0w7TtNKavrPZxP4CEqT2XAQueXsAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    display: block;
    &.sprite-icon_white_accordion_collapsed {
      background-position: -5px -5px;
      width: 44px;
      height: 28px;
    }
    &.sprite-icon_white_accordion_expanded {
      background-position: -5px -43px;
      width: 44px;
      height: 28px;
    }
  }

  a {
    text-decoration: none;
  }

  .line-orange {
    background-image: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 44%, #c16c18 45%, #c16c18 55%, rgba(0, 0, 0, 0) 56%, rgba(0, 0, 0, 0) 100%);
  }

  strong {
    font-weight: 900;
  }

  .sprite.inline {
    display: inline-block;
  }

  .slice-header {
    &-callout {
      margin: 0;
      color: #ddedf9;
      background-color: #c16c18;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
      padding: .5em;
      text-transform: uppercase;
      a {
        color: #ddedf9;
      }
    }
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
    }
    .logo-header {
      position: relative;
      background-color: #022a3a;
      height: 100px;
      z-index: 2;
    }
    .logo-container {
      margin: 0 auto;
      padding: 3em;
      padding-top: .2em;
      padding-bottom: 1.3em;
      margin-bottom: -1.3em;
      background-position: top center;
      background-repeat: no-repeat;
      width: 300px;
      .sprite {
        margin: 0 auto;
      }
    }
  }
  .main {
    position: relative;
    padding-top: 2em;
    background-repeat: no-repeat;
    background-position: 50% -150px;

    h1 {
      text-align: center;
      font-size: 34px;
      color: #425563;
      font-weight: 500;
      margin-top: 1em;
      text-transform: uppercase;

      @media (max-width: $screen-lg) {
        padding: 0 1em;
        font-size: 28px;
      }
    }
    h2 {
      text-align: center;
      font-size: 26px;
      padding: .2em;
    }
    h3 {
      text-align: center;
      font-weight: 900;
      color: #c16c18;
      font-size: 46px;
      text-shadow: 6px 6px 4px #f8e9db;
    }
    h4 {
      display: block;
      margin: 0;
      padding: 12px;
      text-align: center;
      font-size: 36px;
      color: #ddedf9;
      background-color: #c16c18;
      a {
        color: #ddedf9;
      }
      span {
        text-decoration: underline;
        font-weight: 900;
      }

      @media (max-width: $screen-lg) {
        font-size: 28px;
      }
    }
    p {
      text-align: center;
      width: 70%;
      margin: 0 auto;
      margin-top: 1em;
      font-size: 20px;
      padding: 1em;
      line-height: 1.2em;

      @media (max-width: $screen-md) {
        width: 85%;
      }

      @media (max-width: $screen-sm) {
        width: 100%;
      }
    }
  }
  .slice-enrollment-options {
    padding: 24px 10px;
    background-color: #7fa9ae;
    h1 {
      text-align: center;
      color: white;
      font-size: 30px;
      font-weight: 900;
      margin: 24px 0;

      @media (max-width: $screen-md) {
        font-size: 20px;
      }

      @media (max-width: $screen-sm) {
        font-size: 16px;
      }

      @media (max-width: $screen-xs) {
        &:after {
          display: block;
          margin-top: 1em;
          content: " \2190  Scroll  \2192 ";
          font-size: 75%;
          color: rgba(255,255,255,.5);
        }
      }
    }

    .option-container {
      display: flex;

      @media (max-width: $screen-sm) {
        overflow-x: scroll;
      }
    }

    .option-header-images {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-shrink: 0;

      .option-header-image {
        display: flex;
        margin-top: 3rem;
        padding: 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (max-width: $screen-lg) {
          font-size: 12px;
        }

        @media (max-width: $screen-md) {
          margin-top: 4rem;
          padding: 1rem;
        }

        @media (max-width: $screen-sm) {
          margin-top: 3rem;
          min-width: 100px;
        }
      }
    }

    div {
      &.option {
        display: inline-block;
        h2 {
          margin: 0;
          font-size: 18px;
          text-align: center;
          padding: 12px;
          text-transform: uppercase;
          font-weight: 900;
        }
        &.option-high {
          border: 4px solid #425563;
          h2 {
            color: #ddedf9;
            background-color: #425563;
            a {
              color: #ddedf9;
            }
          }
        }
        &.option-standard {
          border: 4px solid #c16c18;
          h2 {
            color: #ddedf9;
            background-color: #c16c18;
            a {
              color: #ddedf9;
            }
          }
        }
        table {
          text-align: center;
          background-color: white;
          th {
            color: #ddedf9;
            background-color: #7fa9ae;
            border: 4px solid white;
            border-spacing: 4px 4px;
            padding: 8px;
            vertical-align: bottom;
            text-align: center;

            @media (max-width: $screen-md) {
              font-size: 12px;
            }

            @media (max-width: $screen-xs) {
              min-width: 100px;
            }

            a {
              color: #ddedf9;
            }
            h3 {
              margin: 0;
              font-weight: 900;
              font-size: inherit;
              color: inherit;
            }

            p {
              margin-top: .5em;
              line-height: 1;
              font-weight: 400;
            }
          }
          td {
            background-color: #ebf6f5;
            border: 4px solid white;
            border-spacing: 0 4px;
          }
          .plan-cost {
            margin: 0;
            font-size: 18px;
            padding: 12px;
            font-weight: 900;

            @media (max-width: $screen-md) {
              font-size: 14px;
            }

            @media (max-width: $screen-sm) {
              font-size: 12px;
            }
          }
          .plan-code-header {
            margin: 0;
            font-size: 18px x-large 4px;

            @media (max-width: $screen-md) {
              font-size: 14px;
            }

            @media (max-width: $screen-sm) {
              font-size: 12px;
            }
          }
          .plan-code {
            margin-bottom: 24px;
            font-size: 36px;
            font-weight: 900;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            line-height: 1;

            @media (max-width: $screen-md) {
              font-size: 24px;
            }

            @media (max-width: $screen-sm) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .slice-enrollment-call {
    text-align: center;
    color: white;
    padding: 24px 0;
    background-color: #022a3a;
    a {
      color: white;
      text-decoration: underline;
    }
    .header {
      padding: 1em 1em;
      color: #ddedf9;
      background-color: #c16c18;
      a {
        color: #ddedf9;
      }
      h1 {
        margin: 0;
        font-size: 42px;
        font-weight: 900;
        display: inline-block;
        color: inherit;

        @media (max-width: $screen-xs) {
          margin-top: 2rem;
          font-size: 28px;
        }
      }
      p {
        font-size: 18px;
        display: inline-block;
        margin-left: 1em;
        width: 66%;
        line-height: 1.25;

        @media (max-width: $screen-xs) {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    table {
      width: 100%;
      color: white;
      text-align: center;
      tr:nth-child(odd) {
        color: #ddedf9;
        background-color: #c16c18;
        a {
          color: #ddedf9;
        }
      }
      th, td {
        padding: .7em;
      }
      th {
        font-size: 22px;
        font-weight: 900;
        text-align: center;

        @media (max-width: $screen-md) {
          font-size: 16px;
        }

        @media (max-width: $screen-xs) {
          font-size: 12px;
        }

        img {
          display: block;
          margin: 0 auto;
          padding-bottom: 8px;
        }
      }
      td {
        font-size: 18px;
        font-weight: 500;

        @media (max-width: $screen-md) {
          font-size: 14px;
        }

        @media (max-width: $screen-xs) {
          font-size: 12px;
        }
      }
    }
    ul {
      padding: 24px;
      list-style: disc;
      margin-left: 24px;
      text-align: left;
      li {
        margin: 0 0 12px 24px;
        font-weight: 300;
        span {
          font-weight: 900;
        }
      }
    }
    p {
      font-size: 18px;

      @media (max-width: $screen-md) {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
      }
    }
  }
  .slice-fed-highlights {
    padding: 24px 0;
    h1 {
      text-align: center;
      font-size: 46px;
      font-weight: 900;
      margin: 1em auto;
      padding-bottom: .2em;
      border-bottom: 4px dotted #c16c18;

      @media (max-width: $screen-xs) {
        margin-top: 0;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 28px;
      }
    }
    ul {
      padding-left: 0;
      list-style: disc;
      li {
        margin-left: 20px;
      }
    }
    table {
      width: 100%;
      font-size: 18px;

      @media (max-width: $screen-xs) {
          font-size: 10px;
      }

      td, th {
        padding: .4em 1em;

        @media (max-width: $screen-xs) {
          padding: .5em;
        }
      }
      td {
        font-weight: 900;
        text-align: center;
        ul {
          font-size: 14px;
          text-align: left;
          font-weight: 300;

          @media (max-width: $screen-xs) {
            font-size: 10px;
            line-height: 1;
          }
        }
      }
      tr {
        > th:first-child {
          text-align: right;
        }
        &:nth-child(even) {
          background-color: #cfe7ea;
        }
        &.fed-highlights-pcp-feature {
          color: #ddedf9;
          background-color: #7fa9ae;
          font-size: 30px;

          @media (max-width: $screen-md) {
            font-size: 24px;
          }

          @media (max-width: $screen-xs) {
              font-size: 12px;
          }

          a {
            color: #ddedf9;
          }
        }
      }
      th {
        &.head-high-option {
          color: #ddedf9;
          background-color: #425563;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;
          width: 20%;

          @media (max-width: $screen-lg) {
            font-size: 14px;
            width: 25%;
          }

          @media (max-width: $screen-md) {
            font-size: 12px;
            width: 30%;
          }
          a {
            color: #ddedf9;
          }
        }
        &.head-standard-option {
          width: 20%;
          color: #ddedf9;
          background-color: #c16c18;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;

          @media (max-width: $screen-lg) {
            font-size: 14px;
            width: 25%;
          }

          @media (max-width: $screen-md) {
            font-size: 12px;
            width: 30%;
          }
          a {
            color: #ddedf9;
          }
        }
      }

      .col-empty {
        padding: 20px;

        @media (max-width: $screen-md) {
          padding: 5px;
        }

        @media(max-width: $screen-xs) {
          padding: 0;
        }
      }

      col {
        &.header {
          width: 40%;
        }
        &.col-high-option {
          border: 4px solid #425563;
        }
        &.col-standard-option {
          border: 4px solid #c16c18;
        }
      }
    }
  }

  .slice-revolutionary {
    background-image: url("/assets/img/landing/landing-back-2.png");
    color: #ddedf9;
    background-color: #022a3a;
    color: white;
    text-align: center;
    padding-bottom: 2em;
    border-top: 8px solid #c16c18;
    border-bottom: 8px solid #7fa9ae;
    a {
      color: #ddedf9;
    }
    h1 {
      font-size: 62px;
    }
    h2 {
      display: inline-block;
      font-size: 62px;
      background-color: #c16c18;
      margin: .25em;
      margin-top: 0;
      padding: .25em;
    }
    p {
      display: block;
      width: 800px;
      margin: .8em auto;
      padding: 1em;
      word-spacing: .2em;
      line-height: 1.5em;
    }
  }

  .slice-change {
    position: relative;
    margin-top: -20px;
    color: white;
    text-align: center;
    background-image: url("/assets/img/landing/landing-back-1.png");
    background-color: #022a3a;
    h1 {
      background-color: #c16c18;
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 900;
      padding: .2em 0;
    }
    h2 {
      font-weight: 900;
      font-size: 70px;
      margin: .3em 0;
    }
    p {
      width: 800px;
      font-size: 18px;
      line-height: 1.2em;
      padding: 1em 1em;
      padding-top: 0;
      margin: 0 auto;
    }
    .triangle {
      position: absolute;
      left: 470px;
      z-index: 1;
      top: 70px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 25px 35px 0 35px;
      border-color: #c16c18 transparent transparent transparent;
    }
  }
  .slice-highlights {
    .left {
      width: 45%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2.5em;
    }
    .right {
      margin-left: 2.5em;
      width: 45%;
      display: inline-block;
      vertical-align: middle;
    }
    .rest {
      position: relative;
      left: -110px;
      bottom: 20px;
    }
    h1 {
      font-size: 50px;
      color: #022a3a;
      font-weight: 900;
      text-align: center;
      width: 800px;
      margin: 0 auto;
      background-image: linear-gradient(to right, #c16c18 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 12px 3px;
      background-repeat: repeat-x;
      padding: .5em 0;
    }
    ul {
      padding: .5em 0;
      list-style-image: url('/assets/img/landing/landing-check.png');
      list-style-position: outside;
      margin-left: 20%;
      li {
        margin: 0;
        font-size: 20px;
        padding: .25em 0;
      }
      ul {
        padding: 0;
        list-style-type: disc;
        list-style-image: none;
        margin-left: 2em;
      }
    }
  }
  .slice-who {
    background-color: #022a3a;
    color: white;
    h1 {
      padding: .25em 0;
      color: #7fa9ae;
      text-align: center;
      font-size: 42px;
      text-transform: uppercase;
      background-color: white;
    }
    div {
      &.who-options {
        display: flex;
      }
      &.who {
        flex-grow: 1;
        flex-basis: 0;
        flex-flow: row wrap;
        text-align: center;
        vertical-align: bottom;
        display: inline-block;
        padding: 2em 0;
        position: relative;
        div.who-sprite-wrap {
          height: 100px;
          .sprite {
            margin: 0 auto;
          }
        }
        p {
          font-size: 30px;
          margin: 1em auto;
        }
        a {
          color: white;
          font-size: 20px;
          &.btn {
            text-transform: uppercase;
            color: #ddedf9;
            background-color: #c16c18;
            font-size: 20px;
            border: 0px solid black;
            padding: .4em 1em;
            border-radius: 6px;
            a {
              color: #ddedf9;
            }
          }
        }
      }
    }
  }

  .slice-video {
    color: #425563;
    background-color: #ebf6f5;
    color: #022a3a;
    border-top: 8px solid #c16c18;
    padding: 1em;
    text-align: center;
    a {
      color: #425563;
    }
    h1 {
      font-size: 30px;
      font-weight: 900;
      margin: .2em;
    }
    p {
      font-size: 18px;
      letter-spacing: .09em;
    }
  }
  .slice-materials {
    padding: 0;
    text-align: center;
    h1 {
      font-size: 42px;
      font-weight: 900;
      margin: .2em;
      text-transform: uppercase;
    }
    .download {
      position: relative;

      &:before {
        position: absolute;
        display: inline-block;
        top: 0;
        left: -1.5em;
        content: "";
        height: 40px;
        width: 36px;

        @media (max-width: $screen-md) {
          top: -0.5em;
          left: -2.5em;
        }

        @media (max-width: $screen-xs) {
          top: .25em;
          left: -3em;
        }
      }

      &:after {
        content: '  [PDF]';
      }
    }

    a {
      vertical-align: middle;
      font-weight: 500;

      &:first-of-type {
        font-size: 30px;
        padding: 1em 0;
        display: block;
        color: #ddedf9;
        background-color: #7fa9ae;

        @media (max-width: $screen-md) {
          font-size: 18px;
        }

        @media (max-width: $screen-xs) {
          padding-left: 4em;
          text-align: left;
          font-size: 16px;
        }

        a {
          color: #ddedf9;
        }
        &:hover {
          color: #425563;
        }
      }
      &:nth-of-type(2) {
        font-size: 30px;
        padding: 1em 0;
        display: block;
        color: #ddedf9;
        background-color: #c16c18;

        @media (max-width: $screen-md) {
          font-size: 18px;
        }

        @media (max-width: $screen-xs) {
          padding-left: 4em;
          text-align: left;
          font-size: 16px;
        }

        a {
          color: #ddedf9;
        }
        &:hover {
          color: #425563;
        }
      }
    }
  }
  .footer-legal {
    padding-bottom: 3rem;
    border-top: 1px dotted black;
    font-size: 12px;
    padding-top: 24px;
    line-height: 1.2em;
    h1 {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 14px;
    }
    h2 {
      margin-top: 1em;
      font-weight: 900;
      font-size: 14px;
    }
    ul {
      list-style: disc inside;
      ul {
        list-style: circle inside;
        margin-left: 3em;
      }
    }
  }
}
