.landing-osr-news {
  padding: 4rem;
  background-color: $global-primary-light;

  @media (max-width: $screen-sm) {
    padding: 2rem;
  }

  &-title {
    position: relative;
    text-align: center;
    color: $global-orange;
    font-family: $font-family-serif-fancy;
    font-size: 1.5em;

    @media (max-width: $screen-sm) {
      font-size: 1.25em;
    }

    &-underline {
      position: absolute;
      margin: 0 auto;
      right: 0;
      bottom: -.2em;
      width: 90%;
    }
  }

  &-title-secondary {
    font-size: 1.75em;

    @media (max-width: $screen-sm) {
      font-size: 1.5em;
    }
  }

  &-subtitle {
    font-family: $font-family-serif;
    text-align: center;
    font-size: 1em;
    margin-bottom: 4rem;

    &-callout {
      position: relative;
      font-weight: 700;
      color: $global-orange;
    }

    &-underline {
      position: absolute;
      width: 100%;
      bottom: -2rem;
      left: 0;
    }
  }

  &-copy {
    font-family: $font-family-serif;
  }

  &-list {
    padding-left: 2em;
    list-style-type: none;
    font-family: $font-family-serif;
    font-style: italic;
    font-weight: 700;
    // font-size: 12px;

    .list-item {
      position: relative;
      margin-bottom: 1em;

      &:before {
        position: absolute;
        top: 0;
        left: -2em;
        font-family: 'Globalhealth';
        content: "\f008";
        color: $global-primary;
      }
    }
  }

  &-cta {
    font-weight: 700;
    font-size: 2em;
    color: $global-orange;
    line-height: 1.2;
  }

  &-panel {
    border-radius: 0;
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &-input {
    display: block;
    margin: .5em .25em;
    padding: .5em .75em;
    width: 100%;
    font-family: $font-family-serif;
    font-size: 12px;
    background-color: rgba(0,0,0,.1);
    border: 0;
    border-radius: .25rem;
    line-height: 1;
  }

  &-input-group {
    display: flex;
    margin-top: -.5em;
    margin-right: -.5em;
  }

  &-button {
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .05em;
  }
}
