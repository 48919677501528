.breadcrumb {
  margin: .5em 0;
  padding: 0;
  background-color: transparent;

  li {
    margin: 0;
    font-weight: 600;

    @media (max-width: $screen-sm) {
      display: block;

      &:first-child {
        &:before {
          padding: 0 5px;
          content: "> ";
          color: $gray-dark;
          font-weight: 500;
        }
      }
    }
  }

  a {
    font-weight: 500;
    text-decoration: none;
  }

  & > li + li:before {
    content: "> ";
    color: $gray-dark;
    font-weight: 500;
  }
}
