.landing-osr-watch {
  display: flex;
  padding-bottom: 1rem;
  align-items: center;

  &-icon {
    margin-left: 5rem;
    width: 12rem;

    @media (max-width: $screen-sm) {
        margin-left: 1rem;
    }
  }

  &-copy {
    margin-top: .75em;
    margin-bottom: 0;
    padding-right: 1em;
    flex-grow: 1;
  }

  &-link {
    color: $global-orange;
    text-decoration: underline;

    &:hover {
      color: darken($global-orange, 10%);
    }
  }
}
