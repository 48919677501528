.landing-osr-perks {
  padding-bottom: 2rem;

  &-icon {
    width: 100%;
  }

  &-title {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }

  &-copy {
    font-size: 12px;
    font-family: $font-family-serif;
  }
}
