li {
  margin: 1em 0;

  a {
    text-decoration: underline;
    font-weight: 500;
  }
}

.list-icon {
  padding-left: 0;
  list-style-type: none;

  li {
    position: relative;
    padding: 0 1.5em 0 2em;

    &:before {
      position: absolute;
      top: -.125em;
      left: 0;
      content: '\f008';
      font-family: 'Globalhealth';
      font-size: 1.25em;
      color: $brand-primary;
    }

    &.list-icon-success {
      &:before {
        color: $brand-success;
      }
    }

    &.list-icon-warning {
      &:before {
        color: $brand-warning;
      }
    }

    &.list-icon-danger {
      &:before {
        color: $brand-danger;
        content: "\f00b";
      }
    }
  }
}
