.search-result-header {
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: $global-blue-5;
  text-transform: uppercase;
  color: $global-blue-1;

  &-item {
    margin: 0;
    padding: 1.25em 0;
    line-height: 1;
    font-weight: 600;
  }
}

.search-result {
  margin-top: 2em;
  margin-bottom: 2em;

  @media (max-width: $screen-lg) {
    padding-left: 1.5em;
  }
}

.search-result-address {
  position: relative;

  &:before {
    position: absolute;
    top: -.375em;
    left: -.875em;
    content: '\f00f';
    font-family: 'Globalhealth';
    font-size: 2em;
    color: $global-link;
  }

  &:after {
    position: absolute;
    padding-top: .25em;
    width: 1.5em;
    height: 1.5em;
    top: -.125em;
    left: -3.125em;
    content: '1';
    font-size: .5em;
    font-weight: 800;
    color: $global-link;
    background-color: white;
    border-radius: 50%;
    line-height: 1;
    text-align: center;
  }
}
