.jumbotron {
  margin-bottom: 0;
  color: white;

  &-title {
    text-transform: uppercase;
    color: white;
  }

  & &-description {
    margin-bottom: 1.5em;
  }

  &-blue {
    background-color: $global-blue-dark;
  }

  &-gold {
    background-color: $global-gold;
  }

  &-purple {
    background-color: $global-purple;
  }

  .btn-secondary {
    color: white;
  }

  &-flag {
    position: absolute;
    top: 0;
    left: 0;
    padding: .5em 2em;
    color: white;
    background-color: $global-gold;
  }

  .container {
    position: relative;
  }
}

.jumbotron-image {
  padding: 6em 0;
  background-color: $global-blue-lighter;
  background-position: center;
  background-size: cover;
  text-align: left;
  height: 500px;
  overflow: hidden;

  @media (max-width: $screen-md) {
    display: flex;
    padding: 4em 1em 0;
    height: 600px;
    justify-content: space-between;
  }

  .jumbotron-flag {
    top: -6em;
  }

  .jumbotron-title {
    text-transform: initial;
    color: $global-blue-dark;
    font-size: 3.5em;
    font-weight: 600;

    @media (max-width: $screen-lg) and (min-width: $screen-sm) {
      font-size: 2.5em;
    }

    @media (max-width: $screen-sm) {
      font-size: 2em;
    }
  }

  .jumbotron-description {
    color: $global-blue-dark;
    font-size: 1.25em;
    font-weight: 500;
  }

  .jumbotron-image-primary {
    margin-top: 16%;
    margin-left: -10%;
    width: 120%;

    @media (min-width: $screen-lg) {
      margin-top: -2.5%;
    }

    @media (max-width: $screen-md) {
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }

    @media (max-width: $screen-sm) {
      margin-top: auto;
    }

    @media (max-width: $screen-xs) {
      width: 100%;
    }
  }

  @media (max-width: $screen-sm) {
    &-background {
      background-color: white;
      background-size: 150%;
      background-repeat: no-repeat;
      background-position-y: 100%;
    }

    &-container,
    &-row {
      height: 100%;
    }

    &-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:after {
        content: initial;
      }
    }
  }
}
