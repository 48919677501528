.landing-medicare-cafe {
  text-align: center;

  &-body {
    padding: 4rem 4rem 2rem 4rem;
    background-color: $global-primary-light;
  }

  &-header {
    padding: 2rem 1rem;
    background-color: $global-orange;
    color: white;
    font-size: 1.5em;
    font-weight: 700;

    @media (max-width: $screen-sm) {
      font-size: 1em;
    }
  }

  &-title {
    font-weight: 300;

    @media (max-width: $screen-sm) {
      font-size: 2em;
    }
  }

  &-icon {
    width: 50%;
    margin: -4rem auto;
  }

  &-legal {
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 12px;
  }
}
