.panel {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &-heading {
    padding: 0;

    .panel-title {
      padding: 1.25em 2em;
    }

    a.panel-title {
      display: block;
    }
  }

  .panel-heading + .panel-collapse {
    .panel-body {
      padding-top: 0;
    }
  }

  &-primary {
    background-color: $global-blue-dark;
    color: white;

    h1, h2, h3, h4, h5, h6, a {
      color: white;
    }
  }

  &-primary-lightest {
    background-color: $global-blue-lightest;
  }

  &-global-blue-0 {
    background-color: $global-blue-0;
  }

  &-global-blue-1 {
    background-color: $global-blue-1;
  }

  &-global-blue-2 {
    background-color: $global-blue-2;
  }

  &-global-blue-3 {
    background-color: $global-blue-3;
  }

  &-global-blue-4 {
    background-color: $global-blue-4;
  }

  &-global-blue-5 {
    background-color: $global-blue-5;
  }

  &-global-purple {
    background-color: $global-purple;
  }

  &-global-gold {
    background-color: $global-gold;
  }

  &-body {
    padding: 2em;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &-description {
    margin-bottom: 1.5em;
  }

  &-secondary {
    background-color: $gray-lighter;

    h1, h2, h3, h4, h5, h6 {
      text-transform: uppercase;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }
}

.panel-group {
  .panel {
    border-radius: 0;
  }

  .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
  }
}

.panel-collapse {
  width: 100%;
}

.panel-collapse-container {
  box-shadow: none;
  background-color: $global-blue-lightest;

  .panel-title {
    padding: 1.25em 1.5em;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1;
  }

  a[data-toggle="collapse"] {
    position: relative;
    text-decoration: none;
    background-color: $global-blue-lightest;
    transition: background-color .1s ease-out;

    &:after {
      content: "\f00b";
      position: absolute;
      top: .875em;
      right: 1.5em;
      font-family: 'Globalhealth';
      font-size: 1.25em;
    }

    &.collapsed {
      background-color: darken($global-blue-lightest, 7.5%);

      &:after {
        content: "\f00a";
      }
    }
  }
}

.panel-collapse-container-drawer {
  a[data-toggle="collapse"] {

    &:after {
      content: "\f00e";
    }

    &.collapsed:after {
      content: "\f00d";
    }
  }
}
