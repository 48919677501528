@import './medicare/alert';
@import './medicare/cafe';
@import './medicare/help';
@import './medicare/legal';
@import './medicare/news';
@import './medicare/perks';
@import './medicare/network';
@import './medicare/service';
@import './medicare/sidenav';
@import './medicare/video';
@import './medicare/watch';

.landing-medicare {
  overflow-x: hidden;
}
