.global-header {
  background-color: #fff;
}

.global-header-nav-small {
  background-color: #61838b;
  color: white;

  &-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  &-icons {
    display: flex;
    padding: .5rem 0;
    align-items: center;

    @media (max-width: $screen-md) {
      justify-content: center;
    }
  }

  &-text {
    display: flex;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  .nav-icon,
  .nav-item {
    display: flex;
    align-items: center;
    color: rgba(255,255,255,.9);
    font-size: 13px;

    @media (max-width: $screen-lg) and (min-width:$screen-md) {
      font-size: 9px;
    }

    &:hover {
      text-decoration: none;
      color: white;
      background-color: darken($global-primary, 10%);
      transition: color .3s ease-out, background-color .3s ease-out;
    }

    &-phone {
      font-size: 16px;

      @media (max-width: $screen-lg) and (min-width:$screen-md) {
        font-size: 11px;
      }
    }
  }

  .nav-icon {
    width: 2em;
    height: 2em;
    margin-right: .5em;
    padding: .25em .2em;
    border-radius: 50%;
    border: 2px solid rgba(255,255,255,.75);
  }

  .nav-item {
    padding: .5em 1em;
    font-weight: 400;
  }

  .nav-item-register-login {
    padding-left: 2em;
    padding-right: 2em;
    height: 100%;
    background-color: $global-gold;

    &:hover {
      background-color: $global-gold;
    }
  }
}

.global-header-nav-big {
  padding: 2rem 0;

  .header-logo {
    display: block;
    margin: 10% auto 0 -10%;
    width: 120%;

    @media (max-width: $screen-lg) and (min-width: $screen-md) {
      margin-top: 0;
    }

    @media (max-width: $screen-md) and (min-width: $screen-xs) {
      margin: 0 auto;
      width: 25%;
    }

    @media (max-width: $screen-xs) {
      margin: 0 auto;
      width: 80%;
    }
  }

  &-items {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    font-size: 14px;

    @media (max-width: $screen-md) {
      flex-direction: column;
      width: 100%;
    }
  }

  .nav-item {
    &-link {
      position: relative;
      display: block;
      padding: 1em;
      font-weight: 700;
      text-transform: uppercase;
      color: $global-primary;
      transition: color .3s ease-out;

      @media (max-width: $screen-lg) and (min-width:$screen-md) {
        font-size: 10px;
      }

      &:hover {
        text-decoration: none;
        color: lighten($global-primary, 10%);
      }
    }

    &.active .nav-item-link {
      color: $global-blue-light;

      &:after {
        position: absolute;
        width: calc(100% - 30px);
        height: 4px;
        bottom: .5em;
        left: 15px;
        content: "";
        background-color: $global-blue-light;
      }
    }
  }

  &-search {
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-md) {
      flex-direction: column-reverse;
      width: 100%;
    }

    &-container {
      display: flex;
      position: relative;
      margin-top: 1.25em;
      overflow: hidden;
      background-color: #f3f5f5;
      border-radius: .25rem;

      @media (max-width: $screen-md) {
        margin: 15px;
        display: flex;
      }
    }

    &-input {
      padding: .5em .75em;
      width: 10em;
      border: 0;
      background-color: transparent;

      @media (max-width: $screen-md) {
        margin-bottom: 0;
        flex: 1;
        width: 100%;
        flex-shrink: 1;
        min-height: 40px;
      }
    }

    &-button {
      padding: .5em .6em;
      border: 0;
      background-color: $global-primary;
      color: rgba(255,255,255,.5);

      &-icon {
        width: 24px;
      }

      @media (max-width: $screen-md) {
        position: absolute;
        flex: 1;
        flex-shrink: 0;
        flex-grow: 0;
        right: 0;
        top: 0;
        z-index: 2;
      }
    }

    &-details {
      font-size: 10px;
      text-align: center;
      margin-top: .25em;
    }
  }

  &-container {
    display: flex;
    height: 120px;
    align-items: center;

    @media (max-width: $screen-lg) {
      height: 60px;
    }

    @media (max-width: $screen-md) {
      height: auto;
      flex-direction: column;
    }
  }
}

// member menu
.global-header-nav-small {
  .nav-item-expand {
    position: relative;

    &:hover {
      .nav-item-member {
        display: block;
      }
    }
  }

  .nav-item-member {
    display: none;
    position: absolute;
    top: 42px;
    right: 0;
    width: 375px;
    text-transform: none;
    text-align: center;
    z-index: 2001;

    .btn-transparent {
      white-space: normal;
    }

    .btn-sm {
      padding: .75em;
    }

    @media (max-width: $screen-lg) {
      width: 325px;
      top: 29px;

      .nav-item-member-title {
        font-size: 1.25em;
      }

      .btn {
        font-size: .75em;
      }

      .btn-sm {
        font-size: .66em;
      }
    }

    &:hover {
      cursor: default;
    }

    a {
      color: white;
    }
  }
}

// mega menu
.global-header-nav-big {
  .nav-item-expand {
    &:hover {
      .nav-item-link {
        color: white;
        background-color: $global-blue-light;
      }

      .nav-item-mega {
        display: block;
      }
    }
  }

  .nav-item-mega {
    display: none;
    padding: 3em 0;
    position: absolute;
    top: 135px;
    left: 0;
    width: 100vw;
    height: 567px;
    background-color: $global-blue-light;
    text-transform: none;
    z-index: 2000;

    @media (max-width: $screen-lg) {
      padding: 2em 0;
    }

    .nav-pills {
      border-radius: 4px;
      overflow: hidden;

      li {
        margin: 0;
        width: 100%;

        a {
          display: block;
          background-color: rgba(0,0,0,.1);
          text-decoration: none;
          border-radius: 0;
          border-bottom: 1px solid rgba(255,255,255,.2);

          @media (max-width: $screen-lg) {
            font-size: 12px;
          }

          &:hover {
            background-color: rgba(0,0,0,.2);
          }
        }

        &.active a {
          cursor: default;
          background-color: $global-primary;

          &:hover {
            background-color: $global-primary;
          }
        }

        &:last-child a {
          border-bottom: 0;
        }
      }
    }

    .nav-tabs {
      margin-bottom: 10px;
      border-bottom: 4px solid rgba(255,255,255,.2);

      li {
        margin: 0 0 -4px 0;

        &:first-child {
          // padding-left: 15px;
        }

        a {
          color: white;
          text-decoration: none;
          border: 0;
          border-bottom: 4px solid transparent;
          transition: border-color .3s ease-out;

          @media (max-width: $screen-lg) {
            padding: 10px;
            font-size: 12px;
          }

          &:hover {
            background-color: transparent;
            border-color: rgba(255,255,255,.75);
          }
        }

        &.active a {
          cursor: default;
          background-color: transparent;
          border: 0;
          border-bottom: 4px solid $global-primary;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    @media (max-width: $screen-lg) {
      top: 95px;
      height: 540px;
    }

    &:hover {
      cursor: default;
    }

    a {
      color: white;
    }

    &-title {
      text-transform: uppercase;
      color: white;
      font-weight: bold;
    }

    &-link {
      font-weight: 300;

      &-bold {
        font-weight: bold;
      }
    }

    &-title,
    &-link,
    &-link-bold {
      font-size: 14px;

      @media (max-width: $screen-lg) and (min-width:$screen-md) {
        font-size: 10px;
      }
    }
  }
}

.off-canvas-search-btn {
  position: absolute;
  padding: 1em;
  top: -2rem;
  left: 0;
  border: 0;
  background: $global-blue;
  z-index: 2;
  line-height: 1;

  .global-header-nav-big-search-button-icon {
    width: 32px;
  }
}

.mega-nav-detail {
  color: rgba(255,255,255,.75);
}
