body {
  min-height: 100vh;
  background-color: $global-blue;
}

.global-main {
  background-color: #fff;
}

.page-header {
  margin: 0;
  padding-bottom: 0;
  background-color: $global-primary-light;
  border: 0;

  &-title {
    text-transform: uppercase;

    @media (max-width: $screen-md) {
      font-size: 22px;
    }
  }
}

.page {
  padding: 2em 2em 2em 0;

  @media (max-width: $screen-md) {
    padding: 1em 0;
  }

  &-title {
    text-transform: uppercase;
  }
}

.page-block {
  margin-top: 2em;
  margin-bottom: 2em;

  &-title {
    text-transform: uppercase;

    @media (min-width: $screen-md) {
      margin-top: 0;
    }
  }

  img {
    width: 100%;
  }
}


.padding-disabled-sm {
  @media (max-width: $screen-md) {
    padding: 0;
  }
}

hr {
  &.dashed {
    border: 1px dashed rgba(0,0,0,.25);
  }
}
