.landing-medicare-legal {
  padding: 4rem;
  text-align: center;
  font-size: 12px;
  font-family: $font-family-serif;
}

.landing-medicare-disclaimer {
  padding: 2em 0;
  text-align: left;
  color: lighten($gray-light, 25%);
  border-top: 1px dashed lighten($gray-light, 25%);
  font-size: 12px;

  li {
    margin: 0;
  }
}
