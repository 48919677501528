.pushy {
  background-color: $global-blue-light;
  color: white;
  overflow-x: hidden;

  ul:first-child {
    margin-top: 0;
  }

  a {
    color: white;
    text-align: left;
    font-size: 12px;
  }

  .nav-icon {
    padding: .5em;
  }

  .nav-item-register-login {
    background-color: $global-gold;
  }

  .global-header-nav-big-container {

    &.open {
      .nav-item-expand {
        display: none;

        &.active {
          display: block;
        }
      }

      & ~ .global-header-nav-small-container {
        display: none;
      }
    }

    .nav-item-expand {
      position: relative;

      .nav-item-link {
        border-bottom: 1px solid rgba(255,255,255,.25);
        font-size: 14px;
        font-weight: 300;
      }

      &:after {
        position: absolute;
        top: 1.125em;
        right: 1em;
        content: '\f00a';
        font-family: 'Globalhealth';
        pointer-events: none;
      }

      &.active {

        .nav-item-mega {
          display: block;
        }

        &:after {
          right: initial;
          left: .75em;
          content: '\f00c';
        }
      }
    }
  }

  .nav-item-mega {
    display: none;

    &-title {
      color: white;
      font-size: 1em;
      padding-left: 30px;
    }
  }

  .nav-pills,
  .nav-tabs {
    display: flex;
    margin-top: 0;
    flex-direction: column;

    li {
      margin: 0;
    }

    [role="tab"] {
      border-bottom: 1px solid rgba(255,255,255,.25);
    }
  }

  .nav-pills {
    margin-top: 0;

    [role="tab"] {
      padding-right: 1.75em;
      padding-left: 1.75em;
      position: relative;

      &:after {
        position: absolute;
        top: .875em;
        right: .5em;
        content: '\f00a';
        font-family: 'Globalhealth';
        pointer-events: none;
      }
    }

    &.open {
      li:not(.active) {
        display: none;
      }
    }

    li.active [role="tab"] {
      background-color: transparent;

      &:after {
        right: initial;
        left: .5em;
        content: '\f00c';
      }
    }
  }

  .container {
    padding: 0;
    width: auto;
  }

  .hidden-offcanvas,
  .hidden-off-canvas {
    display: none;
  }

  .nav-item-mega-title {
    margin: 0;
    padding: 1em;
    border-bottom: 1px dashed rgba(255,255,255,.25);
  }

  .nav li a {
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }

  .mega-nav-detail {
    padding-left: 15px;
  }
}

.off-canvas-menu-btn {
  position: absolute;
  padding: .5em;
  top: -.5em;
  right: .25em;
  background: transparent;
  border: 0;
  font-size: 2em;
  z-index: 2;
  line-height: 1;
}
