.landing-medicare-video {

  &-title {
    padding: 1rem;
    text-align: center;
    background-color: $global-primary-light;
    font-family: $font-family-serif;
    font-size: 1.5em;
  }

  &-img {
    width: 100%;
  }
}