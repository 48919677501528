@import './osr/alert';
@import './osr/cafe';
@import './osr/help';
@import './osr/legal';
@import './osr/news';
@import './osr/perks';
@import './osr/premiums';
@import './osr/service';
@import './osr/sidenav';
@import './osr/video';
@import './osr/watch';

.landing-osr {
  overflow-x: hidden;
}
