body {
  font-size: 15px;
  line-height: 1.66;
}

h1, h2, h3, h4, h5, h6 {
  color: $global-blue-dark;
}

h1 {
  font-weight: 800;
}

h2 {
  font-size: 34px;
  font-weight: 600;
}

h3 {
  font-size: 21px;
  font-weight: bold;
}

h4 {
  font-size: 18px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 16px;
  font-weight: 300;
}

a {
    font-weight: 600;
}

blockquote {
  margin: 1em 0;
  padding: 2em;
  border: 0;
  background-color: $gray-lightest;
  text-transform: uppercase;

  &.nocaps {
    text-transform: none;
  }
}

.text-orange {
  color: $global-orange;
}

.text-orange-light {
  color: $global-orange-light;
}

.text-red {
  color: $global-red;
}

.text-white {
  color: white;
}

.text-serif {
  font-family: $font-family-serif;
}

.text-serif-fancy {
  font-family: $font-family-serif-fancy;
}

.text-italic {
  font-style: italic;
}

.arrow-link {
  &:after {
    margin: 0 .5em;
    content: ">";
  }
}

.center-mobile {
  @media (max-width: $screen-md) {
    text-align: center;
  }
}

a.no-decoration {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
}

.text-white-50 {
  color: rgba(255,255,255,.5);
}
