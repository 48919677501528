.landing-medicare-watch {
  display: flex;
  padding-bottom: 1rem;
  align-items: center;
  background-color: $global-primary;
  color: white;

  &-icon {
    margin-left: 1rem;
    width: 12rem;
  }

  &-copy {
    margin-top: .75em;
    margin-bottom: 0;
    padding-right: 1em;
    font-size: 1.3em;
    flex-grow: 1;
  }

  &-link {
    color: $global-orange-light;
    text-decoration: underline;

    &:hover {
      color: darken($global-orange-light, 10%);
    }
  }
}
