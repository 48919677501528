.landing-osr-premiums {
  padding: 4rem;
  background-color: $global-primary;
  overflow: hidden;

  &-title {
    font-weight: 700;
    font-size: 1.15em;
    color: $global-orange-light;
  }

  &-callout {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid $global-orange;

    .panel-title {
      background-color: $global-orange;
      color: white;
      font-weight: 700;

      @media (max-width: $screen-sm) {
        padding: .5em;
        font-size: 1em;
      }
    }

    .panel-body {
      padding: 15px;
      font-family: $font-family-serif;
      font-size: 1.5em;
    }
  }

  &-panel {
    border-radius: 0;
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
    font-size: 12px;

    .panel-body {
      padding: 15px;
    }
  }

  &-panel-title {
    position: relative;
    margin-bottom: 3rem;
    color: $global-red;
    text-transform: uppercase;
    font-weight: 700;

    &-underline {
      position: absolute;
      margin-left: -10%;
      left: 0;
      bottom: -1rem;
      width: 120%;
    }
  }

  &-panel-clip-left,
  &-panel-clip-right {
    position: absolute;
    top: -9rem;
    width: 3rem;
    pointer-events: none;
  }

  &-panel-clip-left {
    left: 4.5rem;
  }

  &-panel-clip-right {
    right: 4.5rem;
  }

  &-link {
    color: $global-orange-light;
    text-decoration: underline;
    transition: color .3s ease-out;
    font-weight: 500;

    &:hover {
      color: $global-orange;
    }
  }
}
