.action-widget {
  position: relative;
  width: 250px;
  right: 0;

  @media (max-width: $screen-md) {
    position: absolute;
    width: 100%;
  }

  &-container {
    position: absolute;
    width: 100%;
    z-index: 200;
    overflow: visible;

    @media (max-width: $screen-md) {
      position: relative;
      height: 50px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $global-cream;
    text-align: center;
    text-transform: uppercase;

    &-title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-banner {

    &.open:before {
      opacity: .5;
    }

    &:before {
      display: block;
      position: absolute;
      width: 100%;
      height: 500px;
      top: 0;
      opacity: 0;
      pointer-events: none;
      content: "";
      background-color: white;
      transition: opacity .3s ease-out;

      @media (max-width: $screen-md) {
        height: 650px;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 1.5em;
    background-color: $global-orange-burnt;
    color: white;
    transition: background-color .3s ease-out;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: white;
      background-color:  darken($global-orange-burnt, 10%);
    }
  }

  &-body {
    display: none;
    padding: 1em 1.25em;
    font-size: .9em;
    background-color: white;

    &-nav {
      p:last-child {
        margin-bottom: 0;
      }

      .arrow-link {
        color: $global-blue;

        &:hover {
          text-decoration: none;
          color: lighten($global-blue, 10%);
        }
      }
    }
  }

  .form-control,
  .select {
    margin-bottom: .5em;
  }
}
