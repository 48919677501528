.global-footer {

  .nav-item {
    padding: 1.5em .5em;
    color: rgba(255,255,255,.75);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    transition: color .3s ease-out;

    &:hover {
      color: white;
    }
  }

  &-nav {
    background-color: $global-blue-dark;

    &-menu-btn {
      padding: 1em;
      width: 100%;
      border: 0;
      text-align: center;
      font-size: 16px;
      background-color: darken($global-blue-dark, 5%);

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;

      @media (max-width: $screen-lg) {
        font-size: 10px;
      }

      @media (max-width: $screen-md) {
        flex-direction: column;
        text-align: center;
        font-size: auto;
      }
    }
  }

  &-legal {
    padding: 3rem 0;
    background-color: $global-blue;

    @media (max-width: $screen-lg) {
      text-align: center;
    }
  }

  &-nav,
  &-legal {
    color: rgba(255,255,255,.75);
  }

  &-nav {
    font-size: 12px;
  }

  &-legal {
    font-size: 13px;
  }
}
