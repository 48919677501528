.slice-zerorev {
  margin: 0 auto;
  padding: 3em;
  text-align: center;
  color: #ddedf9;
  background-color: #022a3a;

  .zerorev-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: $screen-xs) {
      margin: -3em 0;
    }

    .sprite-landing-zerorev {
      flex-shrink: 0;
    }
  }

  @media (max-width: $screen-md) {
    .sprite-landing-zerorev {
      background-position-x: center;
      transform: scale(.75);
    }
  }

  a {
    color: #ddedf9;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    li {
      margin: 0;
      padding: .7em 0;
      text-transform: uppercase;
      border-bottom: 2px solid #7fa9ae;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.slice-savepcp {
  display: flex;

  @media (max-width: $screen-xs) {
    flex-direction: column;
  }

  .left {
    padding: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ddedf9;
    background-color: #7fa9ae;

    a {
      color: #ddedf9;
    }
  }
  .right {
    padding: 1em 0;
    display: inline-block;
    vertical-align: middle;
    color: #425563;
    background-color: #cfe7ea;
    text-align: left;
    flex-shrink: 0;
    a {
      color: #425563;
    }

    li {
      margin: .5em 0;
    }
  }
  h1 {
    color: inherit;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2em;
    margin: 0 auto;

    @media (max-width: $screen-lg) {
      font-size: 24px;
    }
  }
  ul {
    list-style-position: inside;
    margin-left: 2em;
    margin-right: 3em;
    li {
      font-size: 22px;
      font-weight: 900;
      padding: .12em 0;
      white-space: nowrap;

      @media (max-width: $screen-lg) {
        font-size: 16px;
      }
    }
  }
}

.slice-survey {
  margin-top: 2em;
  padding: 3em 1em 1em;
  border-top: 8px solid #c16c18;
  text-align: center;
  .sprite {
    display: inline-block;
    margin: 1em;

    @media (max-width: $screen-xs) {
      margin: .5em;
    }
  }

  h1 {
    font-size: 60px;
    font-weight: 900;
    color: #c16c18;

    @media (max-width: $screen-xs) {
      font-size: 40px;
    }
  }
  h2 {
    margin-top: 0;
    font-size: 36px;
    color: #7fa9ae;

    @media (max-width: $screen-xs) {
      font-size: 24px;
    }
  }
  ul {
    padding-left: 0;
    list-style-type: circle;
    li {
      color: #c16c18;
      font-size: 22px;
      display: inline;
      margin: auto .3em;
      &::before {
        content: '+ ';
      }

      @media (max-width: $screen-md) {
        display: block;
        font-size: 16px;
      }
    }
  }
  p.legal {
    color: #7fa9ae;
    text-transform: uppercase;
    text-align: right;
    font-size: 10px;
    margin-top: 2em;
    margin-right: 5em;

    @media (max-width: $screen-xs) {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  }
}


.slice-form {
  position: relative;
  color: #ddedf9;
  background-color: #022a3a;
  .form-group {
    label {
      font-size: 12px;
      text-align: left;
    }

    input {
      width: 100%;
    }
  }

  form {
    text-align: center;
    height: 0;
    margin: 0;
    transition: all linear .15s;
    overflow: hidden;
    &.shown {
      display: block;
      padding: 3em;
      text-align: left;
      transition: all linear .15s;
      height: auto;

      @media (max-width: $screen-xs) {
        padding: 1em;
      }
    }
    .checkbox {
      margin: 2rem 0;
      text-align: left;
    }
  }
  a {
    color: #ddedf9;
  }
  h1 {
    margin: 0;
    font-size: 18px;
    text-align: center;
    padding: .75em;
    background-color: #c16c18;
    color: inherit;
  }
  h2 {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: .5em;
    background-color: #022a3a;
    color: inherit;
  }
  .secondary {
    text-align: center;
    a {
      font-size: 18px;
      padding: 1.25em;
      display: block;
      &:hover {
        background-color: #66435a;
        font-weight: 900;
        transition: all linear .11s;
      }
    }

    .title-container {
      position: relative;

      @media (max-width: $screen-xs) {
        display: block;
        width: 75%;
        margin: 0 auto;
      }

      .sprite {
        display: block;
        position: absolute;
        left: -4em;
        top: -1em;

        @media (max-width: $screen-xs) {
          left: -2.5em;
          top: 0;
        }
      }
    }

    .sprite-icon {
      display: inline-block;
      position: absolute;
      top: 70px;
      right: 20px;

      @media (max-width: $screen-xs) {
        top: 82px;
      }
    }
  }
  p.legal {
    font-size: 10px;
    text-align: center;
    padding: 2em;
  }
}
.slice-contact {
  position: relative;
  margin-top: 70px;
  padding-top: 70px;
  background-color: #7fa9ae;
  color: white;
  text-align: center;
  img {
    position: absolute;
    left: calc(50% - 73px);
    top: -70px;
  }
  a {
    color: #ddedf9;
  }
  .sprite {
    display: inline-block;
    margin: auto 24px;
  }
  h1 {
    font-size: 28px;
    padding: 1em;
    font-weight: 500;
    color: inherit;
  }
  p.legal {
    font-size: 10px;
    width: 50%;
    margin: 0 auto;
    padding: 12px 0;
    line-height: 2em;

    @media (max-width: $screen-xs) {
      width: 90%;
    }
  }
}
.slice-footer {
  font-size: 10px;
  padding-bottom: 24px;

  @media (max-width: $screen-md) {
    padding: 2rem;
  }

  .sprite {
    margin-right: .75em;
  }

  .footer-social-container {
    display: flex;
    align-items: center;
  }

  .footer-social {
    display: inline-flex;
    margin-right: 2em;
    align-items: center;
  }
}

.slice-legal,
.footer-legal {
  padding-bottom: 3rem;

  @media (max-width: $screen-md) {
    padding: 2rem;
  }

  h1 {
    font-size: 16px;
  }

  h2 {
    font-size: 14px;
  }

  li {
    margin: 0;
  }
}

#warped {
  position: relative;
  display: block;
  width: 1000px;
  height: 158px;
  > span[class^=w]:nth-of-type(n+0) {
    display: block;
    position: absolute;
    -moz-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  span {
    font-family: 'Lato';
    font-size: 72px;
    font-weight: regular;
    font-style: normal;
    line-height: 0.65;
    white-space: pre;
    overflow: visible;
    padding: 0px;
  }
  .w0 {
    -moz-transform: rotate(-0.61rad);
    -webkit-transform: rotate(-0.61rad);
    -o-transform: rotate(-0.61rad);
    -ms-transform: rotate(-0.61rad);
    transform: rotate(-0.61rad);
    width: 44px;
    height: 46px;
    left: 95.39px;
    top: 137.78px;
  }
  .w1 {
    -moz-transform: rotate(-0.54rad);
    -webkit-transform: rotate(-0.54rad);
    -o-transform: rotate(-0.54rad);
    -ms-transform: rotate(-0.54rad);
    transform: rotate(-0.54rad);
    width: 39px;
    height: 46px;
    left: 137px;
    top: 112.65px;
  }
  .w2 {
    -moz-transform: rotate(-0.48rad);
    -webkit-transform: rotate(-0.48rad);
    -o-transform: rotate(-0.48rad);
    -ms-transform: rotate(-0.48rad);
    transform: rotate(-0.48rad);
    width: 38px;
    height: 46px;
    left: 175.61px;
    top: 91.68px;
  }
  .w3 {
    -moz-transform: rotate(-0.41rad);
    -webkit-transform: rotate(-0.41rad);
    -o-transform: rotate(-0.41rad);
    -ms-transform: rotate(-0.41rad);
    transform: rotate(-0.41rad);
    width: 43px;
    height: 46px;
    left: 214.35px;
    top: 72.49px;
  }
  .w4 {
    -moz-transform: rotate(-0.35rad);
    -webkit-transform: rotate(-0.35rad);
    -o-transform: rotate(-0.35rad);
    -ms-transform: rotate(-0.35rad);
    transform: rotate(-0.35rad);
    width: 25px;
    height: 46px;
    left: 259.68px;
    top: 58.32px;
  }
  .w5 {
    -moz-transform: rotate(-0.29rad);
    -webkit-transform: rotate(-0.29rad);
    -o-transform: rotate(-0.29rad);
    -ms-transform: rotate(-0.29rad);
    transform: rotate(-0.29rad);
    width: 44px;
    height: 46px;
    left: 287.76px;
    top: 46.19px;
  }
  .w6 {
    -moz-transform: rotate(-0.22rad);
    -webkit-transform: rotate(-0.22rad);
    -o-transform: rotate(-0.22rad);
    -ms-transform: rotate(-0.22rad);
    transform: rotate(-0.22rad);
    width: 29px;
    height: 46px;
    left: 335.46px;
    top: 35.88px;
  }
  .w7 {
    -moz-transform: rotate(-0.18rad);
    -webkit-transform: rotate(-0.18rad);
    -o-transform: rotate(-0.18rad);
    -ms-transform: rotate(-0.18rad);
    transform: rotate(-0.18rad);
    width: 18px;
    height: 46px;
    left: 368.9px;
    top: 30.27px;
  }
  .w8 {
    -moz-transform: rotate(-0.13rad);
    -webkit-transform: rotate(-0.13rad);
    -o-transform: rotate(-0.13rad);
    -ms-transform: rotate(-0.13rad);
    transform: rotate(-0.13rad);
    width: 43px;
    height: 46px;
    left: 391.5px;
    top: 24.98px;
  }
  .w9 {
    -moz-transform: rotate(-0.06rad);
    -webkit-transform: rotate(-0.06rad);
    -o-transform: rotate(-0.06rad);
    -ms-transform: rotate(-0.06rad);
    transform: rotate(-0.06rad);
    width: 43px;
    height: 46px;
    left: 439.31px;
    top: 20.83px;
  }
  .w10 {
    -moz-transform: rotate(0.02rad);
    -webkit-transform: rotate(0.02rad);
    -o-transform: rotate(0.02rad);
    -ms-transform: rotate(0.02rad);
    transform: rotate(0.02rad);
    width: 44px;
    height: 46px;
    left: 487.29px;
    top: 20.17px;
  }
  .w11 {
    -moz-transform: rotate(0.08rad);
    -webkit-transform: rotate(0.08rad);
    -o-transform: rotate(0.08rad);
    -ms-transform: rotate(0.08rad);
    transform: rotate(0.08rad);
    width: 29px;
    height: 46px;
    left: 536.22px;
    top: 22.43px;
  }
  .w12 {
    -moz-transform: rotate(0.14rad);
    -webkit-transform: rotate(0.14rad);
    -o-transform: rotate(0.14rad);
    -ms-transform: rotate(0.14rad);
    transform: rotate(0.14rad);
    width: 38px;
    height: 46px;
    left: 569.96px;
    top: 26.85px;
  }
  .w13 {
    -moz-transform: rotate(0.19rad);
    -webkit-transform: rotate(0.19rad);
    -o-transform: rotate(0.19rad);
    -ms-transform: rotate(0.19rad);
    transform: rotate(0.19rad);
    width: 22px;
    height: 46px;
    left: 612.45px;
    top: 32.8px;
  }
  .w14 {
    -moz-transform: rotate(0.25rad);
    -webkit-transform: rotate(0.25rad);
    -o-transform: rotate(0.25rad);
    -ms-transform: rotate(0.25rad);
    transform: rotate(0.25rad);
    width: 41px;
    height: 46px;
    left: 638.53px;
    top: 40.93px;
  }
  .w15 {
    -moz-transform: rotate(0.32rad);
    -webkit-transform: rotate(0.32rad);
    -o-transform: rotate(0.32rad);
    -ms-transform: rotate(0.32rad);
    transform: rotate(0.32rad);
    width: 44px;
    height: 46px;
    left: 682.56px;
    top: 54.43px;
  }
  .w16 {
    -moz-transform: rotate(0.39rad);
    -webkit-transform: rotate(0.39rad);
    -o-transform: rotate(0.39rad);
    -ms-transform: rotate(0.39rad);
    transform: rotate(0.39rad);
    width: 38px;
    height: 46px;
    left: 728.62px;
    top: 70.59px;
  }
  .w17 {
    -moz-transform: rotate(0.44rad);
    -webkit-transform: rotate(0.44rad);
    -o-transform: rotate(0.44rad);
    -ms-transform: rotate(0.44rad);
    transform: rotate(0.44rad);
    width: 18px;
    height: 46px;
    left: 768.76px;
    top: 84px;
  }
  .w18 {
    -moz-transform: rotate(0.49rad);
    -webkit-transform: rotate(0.49rad);
    -o-transform: rotate(0.49rad);
    -ms-transform: rotate(0.49rad);
    transform: rotate(0.49rad);
    width: 43px;
    height: 46px;
    left: 787.9px;
    top: 100.1px;
  }
  .w19 {
    -moz-transform: rotate(0.56rad);
    -webkit-transform: rotate(0.56rad);
    -o-transform: rotate(0.56rad);
    -ms-transform: rotate(0.56rad);
    transform: rotate(0.56rad);
    width: 42px;
    height: 46px;
    left: 829.29px;
    top: 124.24px;
  }
  .w20 {
    -moz-transform: rotate(0.63rad);
    -webkit-transform: rotate(0.63rad);
    -o-transform: rotate(0.63rad);
    -ms-transform: rotate(0.63rad);
    transform: rotate(0.63rad);
    width: 34px;
    height: 46px;
    left: 868.73px;
    top: 148.58px;
  }
}
