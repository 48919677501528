.landing-osr-sidenav {
  list-style-type: none;
  padding: 0;
  font-size: 12px;

  .list-item {
    transition: background-color .3s ease-out;

    &:hover {
      background-color: rgba(0,0,0,.1);
    }

    &.active-primary {
      background-color: $global-primary;

      &:hover {
        background-color: darken($global-primary, 10%);
      }
    }

    &.active-secondary {
      background-color: $global-purple;

      &:hover {
        background-color: darken($global-purple, 10%);
      }
    }

    &.active-primary,
    &.active-secondary {
      .nav-item {
        color: white;
      }
    }
  }

  .nav-item {
    display: block;
    position: relative;
    padding: .5em .75em .5em 2em;
    text-decoration: none;

    &:before {
      position: absolute;
      top: .5em;
      left: .75em;
      content: ">";
    }
  }

  &-select-container {
    display: flex;
  }

  &-select-wrapper {
    position: relative;
    flex-grow: 1;

    &:after {
      position: absolute;
      pointer-events: none;
      content: "";
      font-weight: 700;
      top: .66em;
      right: .75em;
      width: 0;
      height: 0;
      border-left: .4em solid transparent;
      border-right: .4em solid transparent;

      border-top: .5em solid rgba(0,0,0,.25);
    }
  }

  &-select {
    padding: .25em .5em;
    border-radius: 0;
    width: 100%;
    -webkit-appearance: none;
    font-size: 12px;
  }

  &-select-button {
    padding: .5em .75em;
    border: 0;
    border-radius: 0;
    color: white;
    background-color: $global-primary;
    font-size: .75em;
  }
}