.feature-light {
  background-color: $global-blue-5;
}

.feature {
  padding: 2em 0;

  &-title,
  &-title-left {
    text-transform: uppercase;
    margin: 1em 0;

    &-link {
      color: $global-blue;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-title {
    text-align: center;
  }

  &-subtitle {
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: 600;
  }

  &-main {
    &-img {
      width: 100%;
      background-color: darkgray;
    }

    &-title {
      font-size: 21px;
      text-transform: uppercase;
    }

    &-title,
    &-subtitle {
      margin: 1.5em 0 1em;
      font-weight: 600;
      color: $global-blue;
    }
  }

  &-item {
    margin-bottom: 1em;

    &-img {
      width: 100%;
      background-color: darkgray;
      @media (max-width: $screen-md) {
        margin-top: .5em;
      }
    }

    &-cal {
      background-color: $global-gold;
      text-align: center;
      color: white;

      @media (max-width: $screen-sm) {
        margin-top: 1em;
        font-size: 10px;
      }

      &-year {
        background-color: $global-gold-dark;
        font-weight: 600;
        line-height: 1;
        padding: .5em 0;
      }

      &-month {
        padding: .25em 0;
      }

      &-day {
        padding-bottom: .125em;
        font-size: 3em;
        font-weight: 600;
        line-height: 1;
      }
    }
  }
}
