.form-control,
input,
select,
textarea {
  padding: 1.25em 1.5em;
  height: auto;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.1);
  background-color: white;
  font-size: 14px;
  font-style: italic;
  line-height: 1;
  color: $gray-light;

  @media (max-width: $screen-md) {
    margin-bottom: 1em;
  }
}

select {
  width: 100%;
  height: 54px;
  appearance: none;
  cursor: pointer;
}

.select {
  position: relative;

  &:after {
    position: absolute;
    content: '\f00d';
    top: 1em;
    right: 1em;
    font-family: 'Globalhealth';
    color: $global-primary;
    pointer-events: none;
  }
}

.input-group-button {
  display: flex;

  input {
    padding: .5em .75em;
    font-size: 10px;
    border-radius: 0;
  }

  .btn-sm {
    padding: .5em .75em;
    border-radius: 0;
  }
}
